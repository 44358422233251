// import axios from "axios";
import store from "./store";

export default class Service {

    static get host() {
        return process.env.VUE_APP_SERVICE_HOST;
    }

    static get serverRdpUrl() {
        return `${Service.host}/servers/rdp`;
    }

    static get serverEventsUrl() {
        return `${Service.host}/servers/listen`;
    }

    static get messagesEventsUrl() {
        return `${Service.host}/messaging/listen`;
    }

    static get certificateDownloadUrl() {
        return `${Service.host}/certificate/download`;
    }

    static get currentUser() {
        return store.state.user;
    }

    static get currentUserId() {
        return Service.currentUser?.id ?? -1;
    }

    static get config() {
        return {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Service.currentUser?.token}`,
                "X-Current-User": Service.currentUserId,
            },
        };
    }

    static parseError(error) {
        console.warn(error);

        return { message: error };
    }

    static ajaxGet(url) {
        return new Promise(resolve => {
            fetch(url, { headers: Service.config.headers })
                .then(response => response.json())
                .then(data => resolve({ success: true, data }))
                .catch(error => resolve(Service.parseError(error)));
        });
    }

    static ajaxPost(url, params) {
        return new Promise(resolve => {
            fetch(url, { method: 'POST', headers: Service.config.headers, body: JSON.stringify(params) })
                .then(response => response.json())
                .then(data => resolve({ success: true, data }))
                .catch(error => resolve(Service.parseError(error)));
        });
    }

    static ajaxPut(url, params) {
        return new Promise(resolve => {
            fetch(url, { method: 'PUT', headers: Service.config.headers, body: JSON.stringify(params) })
                .then(response => response.json())
                .then(data => resolve({ success: true, data }))
                .catch(error => resolve(Service.parseError(error)));
        });
    }

    static ajaxDelete(url) {
        return new Promise(resolve => {
            fetch(url, { method: 'DELETE', headers: Service.config.headers })
                .then(response => response.json())
                .then(data => resolve({ success: true, data }))
                .catch(error => resolve(Service.parseError(error)));
        });
    }

    static ajaxFile(url, params) {
        return new Promise(resolve => {
            fetch(url, { method: 'POST', headers: Service.config.headers, body: JSON.stringify(params) })
                .then(response => response.blob())
                .then(data => resolve({ success: true, data }))
                .catch(error => resolve(Service.parseError(error)));
        });
    }

    static async getCertificate() {
        let result = {};

        try {
            result = await Service.ajaxGet(`${Service.host}/certificate`);

            if (!result.success) {
                return result;
            }

        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async downloadCertificate(params) {
        let result = {};

        try {
            result = await Service.ajaxFile(`${Service.host}/certificate/download`, params);

            if (!result.success) {
                return result;
            }

            const url = window.URL.createObjectURL(result.data);
            const a = document.createElement('a');

            a.href = url;
            a.download = "taitus.pfx";

            document.body.appendChild(a);

            a.click();
            a.remove();
        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async getSsl() {
        let result = {};

        try {
            result = await Service.ajaxGet(`${Service.host}/certificate/ssl`);

            if (!result.success) {
                return result;
            }

        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async updateCertificates(params) {
        let result = {};
        let url = `${Service.host}/servers/update-certificates`;

        try {
            result = await Service.ajaxPost(url, params);

            if (!result.success) {
                return result;
            }

        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async requestServer(server) {
        const message = {
            sender: Service.currentUserId,
            receiver: server.takenById,
            content: `[${Service.currentUser.fullName}] is requesting access to the server [${server.name}]`
        }

        await Service.ajaxPost(`${Service.host}/messaging/send`, message);
    }

    static async getAllServers() {
        let result = {};

        try {
            result = await Service.ajaxGet(`${Service.host}/servers/list`);

            if (!result.success) {
                return result;
            }

        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async getServer(id) {
        let result = {};

        try {
            result = await Service.ajaxGet(`${Service.host}/servers/${id}`);

            if (!result.success) {
                return result;
            }

        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async useServer(id, userid, comments) {
        let result = {};

        try {
            result = await Service.ajaxPost(`${Service.host}/servers/use/${id}`, { userid, comments });

            if (!result.success) {
                return result;
            }

        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async releaseServer(id) {
        let result = {};

        try {
            result = await Service.ajaxPost(`${Service.host}/servers/release/${id}`);
        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async deleteServer(id) {
        let result = {};

        try {
            result = await Service.ajaxDelete(`${Service.host}/servers/${id}`);
        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async addServer(server) {
        let result = {};

        try {
            result = await Service.ajaxPost(`${Service.host}/servers`, server);
        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async updateServer(server) {
        let result = {};

        try {
            result = await Service.ajaxPut(`${Service.host}/servers/${server.id}`, server);
        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

    static async login(username, password) {
        let result = {};

        try {
            result = await Service.ajaxPost(`${Service.host}/users/login`, { username, password });

            if (!result.success) {
                return result;
            }

        } catch (error) {
            result.message = error.message;
        }

        return result;
    }

}