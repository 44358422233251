<template>
  <div id="app">
    <Navbar />
    <div class="p-3">
      <router-view />
    </div>

    <div
      id="version"
      class="badge badge-pill badge-light shadow-sm text-muted font-italic"
    >
      {{ version }}
    </div>
  </div>
</template>

<script>
import Configuration from "@/configuration";
import Utils from "@/utils";
import Service from "@/service";
import Navbar from "@/components/Navbar";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      serverSentEvents: null,
      messagesEvents: null,
    };
  },

  components: {
    Navbar,
  },

  computed: {
    ...mapState(["message", "user", "servers", "notifications"]),

    version() {
      return Configuration.version;
    },

    usedServers() {
      return this.servers?.filter((x) => x.isTaken) ?? [];
    },

    serverNotifications() {
      return this.notifications.filter((x) => x.server != null);
    },
  },

  watch: {
    message: {
      handler(message) {
        if (!message.content) return;

        this.$bvToast.toast(message.content, {
          variant: message.severity,
          solid: true,
          toaster: "b-toaster-top-center",
        });
      },
      deep: true,
    },

    usedServers: {
      handler(servers) {
        if (!Utils.notificationEnabled || !this.serverNotifications.length)
          return;

        const ids = servers.map((x) => x.id);

        for (const notification of this.serverNotifications) {
          if (!ids.includes(notification.server.id)) {
            this.notify(notification);
          }
        }
      },
      deep: true,
    },

    user: {
      handler(user) {
        if (user) {
          this.initEventsources(user.id);
        } else {
          this.killEventSources();
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(["refreshServers", "removeNotification"]),

    initEventsources(userid) {
      this.serverSentEvents = new EventSource(
        `${Service.serverEventsUrl}/${userid}`
      );
      this.messagesEvents = new EventSource(
        `${Service.messagesEventsUrl}/${userid}`
      );

      this.serverSentEvents.onmessage = this.onServer;
      this.messagesEvents.onmessage = this.onMessage;

      this.serverSentEvents.onopen = () => {
        console.debug(`serverSentEvents open`);
      };
      this.messagesEvents.onopen = () => {
        console.debug(`messagesEvents open`);
      };

      this.serverSentEvents.onerror = (e) => {
        console.error(`serverSentEvents error`, e);
      };
      this.messagesEvents.onerror = (e) => {
        console.error(`messagesEvents error`, e);
      };
    },

    killEventSources() {
      this.serverSentEvents.kill();
      this.messagesEvents.kill();

      this.serverSentEvents = null;
      this.messagesEvents = null;
    },

    onServer(e) {
      if (!e.data) return;

      const servers = JSON.parse(e.data);

      console.debug(
        e.lastEventId,
        servers.map((x) => `${x.id}: ${x.name} (${x.isTaken})`)
      );

      this.refreshServers(servers);
    },

    onMessage(e) {
      const message = JSON.parse(e.data);

      new Notification(message.content);
    },

    notify(notification) {
      this.removeNotification(notification);

      const title = `Server "${notification.server.name}" is now available`;

      new Notification(title);
    },
  },

  mounted() {
    if (this.user && !this.messagesEvents) {
      this.initEventsources(this.user.id);
    }

    this.refreshServers();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
