import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/account/Login.vue"),
    meta: {

    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import("@/views/account/Logout.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import("@/views/account/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/servers',
    name: 'servers',
    component: () => import("@/views/servers/List.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ssl',
    name: 'ssl',
    component: () => import("@/views/servers/SSL.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/shopping',
    name: 'shopping',
    component: () => import("@/views/shopping/List.vue"),
    meta: {
      requiresAuth: true,
    },

  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import("@/views/links/Calendar.vue"),
    meta: {
      requiresAuth: true,
    },

  },
  {
    path: '/time-sheets',
    name: 'timeSheets',
    component: () => import("@/views/links/TimeSheets.vue"),
    meta: {
      requiresAuth: true,
    },

  },
  {
    path: '/holidays',
    name: 'holidays',
    component: () => import("@/views/links/Holidays.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: {

    },

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  store.state.message.content = null;

  delete sessionStorage.changed;

  if (requiresAuth && !store.state.user) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router
