export default class Configuration {
    static get version() {
        return "v1.5.1";
    }

    static get percentLimits() {
        return { danger: 80, warning: 60 };
    }

    static getPercentVariant(percent) {
        if (percent > Configuration.percentLimits.danger) return "danger";
        if (percent > Configuration.percentLimits.warning) return "warning";
        if (percent < 1 || isNaN(percent)) return "dark";

        return "primary";
    }
}