<template>
  <b-navbar toggleable="lg" type="dark" variant="primary">
    <b-navbar-brand :to="{ name: 'home' }" class="mr-5"
      >Taitus Dashboard</b-navbar-brand
    >

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="user">
        <b-nav-item
          :to="{ name: 'servers' }"
          title="Available / In use"
          v-b-tooltip.hover
        >
          <i class="fas fa-desktop mr-1"></i>
          <span>Servers</span>
          <b-badge pill class="ml-2" variant="dark"
            >{{ availableServers.length }} / {{ usedServers.length }}</b-badge
          >
        </b-nav-item>
        <b-nav-item
          :to="{ name: option.route }"
          v-for="option of options"
          :key="option.route"
          :disabled="option.disabled"
        >
          <i class="mr-1" :class="option.icon"></i>
          <span>{{ option.title }}</span>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right v-if="user">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <i class="fas fa-user mr-2"></i>
            <em class="mr-2">{{ user.fullName }}</em>
          </template>
          <b-dropdown-item :to="{ name: 'profile' }">
            <span> <i class="far fa-address-card mr-2"></i> Profile </span>
          </b-dropdown-item>

          <hr class="my-2" />

          <b-dropdown-item :to="{ name: 'logout' }">
            <span class="text-danger">
              <i class="fas fa-sign-out-alt mr-2"></i> Logut
            </span>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item href="#" v-if="!user">Login</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  params: {},

  data() {
    return {
      options: [],
    };
  },

  computed: {
    ...mapState(["user", "servers"]),

    availableServers() {
      return this.servers?.filter((x) => !x.isTaken) ?? [];
    },

    usedServers() {
      return this.servers?.filter((x) => x.isTaken) ?? [];
    },
  },

  mounted() {
    this.options = [
      {
        title: "SSL",
        route: "ssl",
        icon: "fas fa-lock",
      },
      {
        title: "Shopping List",
        route: "shopping",
        icon: "fas fa-shopping-cart",
        disabled: true,
      },
      {
        title: "Calendar",
        route: "calendar",
        icon: "fas fa-calendar-alt",
        disabled: this.user.calendarurl == null,
      },
      {
        title: "Time Sheets",
        route: "timeSheets",
        icon: "fas fa-clock",
        disabled: this.user.timesheetsurl == null,
      },
      {
        title: "Holidays",
        route: "holidays",
        icon: "fas fa-plane-departure",
        disabled: this.user.holidaysurl == null,
      },
    ];
  },
};
</script>