import Vue from 'vue'
import Vuex from 'vuex'
import Service from "@/service";
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.user || "null"),

    servers: [],

    shopping: JSON.parse(localStorage.shopping || "[]"),

    notifications: JSON.parse(localStorage.notifications || "[]"),

    message: {
      content: null,
      severity: null,
    },
  },

  mutations: {
    setState(state, params) {
      state[params.name] = params.value;
      localStorage[params.name] = JSON.stringify(params.value);
    },

    addNotification(state, params) {
      params.id = (new Date()).getTime();
      state.notifications.push(params);
      localStorage.notifications = JSON.stringify(state.notifications);
    },

    removeNotification(state, params) {
      state.notifications = state.notifications.filter(x => x.id != params.id);
      localStorage.notifications = JSON.stringify(state.notifications);
    }
  },

  actions: {
    setState({ commit }, params) {
      commit("setState", params);
    },

    async login({ commit }, user) {
      const result = await Service.login(user.username, user.password);

      if (result.success) {
        commit("setState", { name: "user", value: result.data });

        router.replace({ name: "home" });
      } else {
        commit("setState", {
          name: "message",
          value: { content: result.message, severity: "danger" },
        });
      }
    },

    logout({ commit }) {
      commit("setState", { name: "user", value: null });

      localStorage.clear();
      sessionStorage.clear();

      router.replace({ name: "login" });
    },

    addNotification({ commit }, params) {
      const notification = {};

      notification[params.type] = params.value;

      commit('addNotification', notification)
    },

    removeNotification({ commit }, id) {
      commit('removeNotification', id)
    },

    async refreshServers({ commit }, servers) {
      if (servers) {
        return commit("setState", { name: "servers", value: servers });
      }

      const result = await Service.getAllServers();

      if (result.success) {
        commit("setState", { name: "servers", value: result.data });
      }
    },

    async useServer({ dispatch }, params) {
      await Service.useServer(params.id, params.userid, params.comments);
      dispatch('refreshServers');
    },

    async releaseServer({ dispatch }, server) {
      await Service.releaseServer(server.id);
      dispatch('refreshServers');
    },

    async updateServer({ dispatch }, server) {
      await Service.updateServer(server);
      dispatch('refreshServers');
    },

    async deleteServer({ dispatch }, server) {
      await Service.deleteServer(server.id);
      dispatch('refreshServers');
    },

    async addServer({ dispatch }, server) {
      await Service.addServer(server);
      dispatch('refreshServers');
    },
  },

  modules: {
  }
})
