export default class Utils {

    static get notificationEnabled() {
        return Notification.permission === "granted";
    }

    static toDateString(date, includeTime = true) {
        if (typeof date == 'string') {
            date = new Date(date);
        }

        const options = { month: "short", day: "2-digit", year: "numeric" };

        if (includeTime) {
            options.hour = "2-digit";
            options.minute = "2-digit";
            options.second = "2-digit";
            options.hour12 = false;
        }

        const result = new Intl.DateTimeFormat("es", options).format(date);

        return result;
    }

    static toast(
        sender,
        message,
        variant,
        title = null,
        autoHideDelay = 2500,
        append = false
    ) {
        sender.$bvToast.toast(message, {
            title: title,
            toaster: "b-toaster-bottom-center",
            variant: variant,
            autoHideDelay: autoHideDelay,
            appendToast: append,
            solid: true,
        });
    }

    static toastOk(sender, message, title = "OK") {
        Utils.toast(sender, message, "success", title);
    }

    static toastError(sender, message, title = "Something went wrong") {
        Utils.toast(sender, message, "danger", title);
    }
}